<template>
  <div class="container-web-app">
    <ArrowBack :path-name="toPageUrl($options.name)" />
    <br />
    <v-subheader class="headline text-center font-weight-bold d-block"> Accurate data converter </v-subheader>
    <div class="subtitle-1">This converter will sum up any supported data units and output them in various units.</div>

    <v-expansion-panels :value="0" class="mt-4">
      <v-expansion-panel
        :class="{
          'v-expansion-panel--dark': isDarkModeEnabled,
          'v-expansion-panel--light': !isDarkModeEnabled
        }"
      >
        <v-expansion-panel-header> Supported units & available outputs </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table class="mt-4" dense>
            <template v-slot:default>
              <!--suppress JSUnusedLocalSymbols -->
              <tr v-for="(_, i) in supportedBits" :key="i">
                <td>{{ supportedBits[i] }}</td>
                <td>{{ supportedBytes[i] }}</td>
              </tr>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        :class="{
          'v-expansion-panel--dark': isDarkModeEnabled,
          'v-expansion-panel--light': !isDarkModeEnabled
        }"
      >
        <v-expansion-panel-header> Input examples </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table class="text-left" dense>
            <template v-slot:default>
              <tr v-for="example in examples" :key="example">
                <th>{{ example }}</th>
              </tr>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-textarea v-model="query" autofocus class="mt-4" placeholder="Enter your input" />

    <transition name="slide">
      <div v-if="query !== ''">
        <DataConverterUnitTable key="bits" :bits="bits" :units="supportedBits" unit-bit-or-byte="bit" />

        <DataConverterUnitTable key="bytes" :bits="bits" :units="supportedBytes" class="mt-4" unit-bit-or-byte="byte" />
      </div>
    </transition>
  </div>
</template>

<script>
import ArrowBack from "@/components/ArrowBack";
import { mapGetters } from "vuex";
import Big from "big.js";
import DataConverterUnitTable from "./data-converter/DataConverterUnitTable";

export default {
  name: "DataConverter",
  components: { DataConverterUnitTable, ArrowBack },
  data() {
    return {
      dataConverterInstance: null,
      query: "",
      examples: ["10 bytes", "20Mb", "2.1 Gigabytes", "100 KB", "10,000,00.5 pb", "10000.894 tB"],
      // prettier-ignore
      supportedBits: ["Bit", "Kilobit", "Megabit", "Gigabit", "Terabit", "Petabit", "Exabit", "Zettabit", "Yottabit"],
      // prettier-ignore
      supportedBytes: ["Byte", "Kilobyte", "Megabyte", "Gigabyte", "Terabyte", "Petabyte", "Exabyte", "Zettabytes", "Yottabyte"],
      get unitsMap() {
        const _this = this;
        return {
          bits: {
            get short() {
              const unitBitsShort = _this.supportedBits.slice(1).map(unit => `${unit[0]}b`);
              return ["bit", ...unitBitsShort];
            },
            get long() {
              const unitBitsLong = _this.supportedBits.slice(1).map(unit => unit.toLowerCase());
              return ["bit", ...unitBitsLong];
            }
          },
          bytes: {
            get short() {
              const unitBytesShort = _this.supportedBytes.slice(1).map(unit => `${unit[0].toLowerCase()}b`);
              return ["byte", ...unitBytesShort];
            },
            get long() {
              const unitBytesLong = _this.supportedBytes.slice(1).map(unit => unit.toLowerCase());
              return ["byte", ...unitBytesLong];
            }
          }
        };
      },
      two: new Big(2),
      base: new Big(2 ** 10),
      bitsInByte: new Big(8)
    };
  },
  computed: {
    ...mapGetters(["isProductActive", "commaSeparated", "languageDefault", "toPageUrl"]),
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.$options.name);
    },
    bits() {
      let bits = new Big(0);
      for (const line of this.query.split("\n")) {
        if (!line.trim()) {
          continue;
        }

        let { number, unit } = this.getNumberUnitPair(line);
        if (!number || !unit) {
          continue;
        }
        unit = unit.replace(/s/g, "");
        number = new Big(number);

        let typeUnit;
        let exponent = 0;
        for (; exponent < this.unitsMap.bits.short.length; exponent++) {
          const unitBitShort = this.unitsMap.bits.short[exponent];
          const unitBitLong = this.unitsMap.bits.long[exponent];
          if (unitBitShort === unit || unitBitLong === unit.toLowerCase()) {
            typeUnit = "bits";
            break;
          }

          const unitByteShort = this.unitsMap.bytes.short[exponent];
          const unitByteLong = this.unitsMap.bytes.long[exponent];
          if (unitByteShort === unit.toLowerCase() || unitByteLong === unit.toLowerCase()) {
            typeUnit = "bytes";
            break;
          }
        }

        switch (typeUnit) {
          case "bits": {
            bits = bits.add(number.times(this.base.pow(exponent)));
            break;
          }
          case "bytes": {
            bits = bits.add(number.times(this.base.pow(exponent)).times(this.bitsInByte));
            break;
          }
        }
      }

      return bits;
    }
  },
  methods: {
    getNumberParts(number) {
      if (!number.match(/\d/)) {
        return "";
      }
      const [integer, fraction] = number.replace(/,/g, "").split(".");

      const numberParts = [integer];

      if (fraction) {
        numberParts.push(fraction);
      }

      // noinspection JSStringConcatenationToES6Template
      return numberParts.join(".");
    },
    getNumberUnitPair(line) {
      let number = line.replace(/[A-Za-z]+/g, "").trim();
      if (!number) {
        return {};
      }
      const unit = line.replace(number, "").trim();
      number = this.getNumberParts(number);
      return { number, unit };
    },
    numberFormatToObject(numberFormat) {
      return numberFormat.reduce((object, { type, value }) => {
        object[type] = value;
        return object;
      }, {});
    }
  }
};
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.slide-leave,
.slide-enter-to {
  max-height: 600px;
}
</style>
